<template>
    <base-dialog-form
        :dialog="dialog"
        width="800"
        :loading="loading"
        :title="`${!configurationsData ? 'Create New' : 'Edit' } Configuration`"
        actionText="Save details"
        @close="$emit('close')"
        @submit="submit()"
    >
        <template #content>
            <v-row>  
                    <v-col cols="6">
                        <v-text-field
                            dense
                            outlined
                            persistent-hint
                            label="Paybill Name *"
                            class="rounded-lg"
                            v-model="configurationObj.organisationName"
                            :hint="errors.get('organisationName')"
                            :error="errors.has('organisationName')"
                            @input="errors.clear('organisationName')"
                        ></v-text-field>
                    </v-col>      
                
                    <v-col cols="6">
                        <v-text-field
                            dense
                            outlined
                            persistent-hint
                            label="Paybill Number *"
                            class="rounded-lg"
                            v-model="configurationObj.paybillNo"
                            :hint="errors.get('paybillNo')"
                            :error="errors.has('paybillNo')"
                            @input="errors.clear('paybillNo')"
                        ></v-text-field>
                    </v-col>  
                
                    <v-col cols="6">
                        <v-text-field
                            :disabled="configurationsData"
                            dense
                            outlined
                            persistent-hint
                            label="Consumer Secret *"
                            class="rounded-lg"
                            v-model="configurationObj.consumerSecret"
                            :hint="errors.get('consumerSecret')"
                            :error="errors.has('consumerSecret')"
                            @input="errors.clear('consumerSecret')"
                        ></v-text-field>
                    </v-col>  
                
                    <v-col cols="6">
                    <v-text-field
                            dense
                            outlined
                            persistent-hint
                            :disabled="configurationsData"
                            label="Consumer Key *"
                            class="rounded-lg"
                            v-model="configurationObj.consumerKey"
                            :hint="errors.get('consumerKey')"
                            :error="errors.has('consumerKey')"
                            @input="errors.clear('consumerKey')"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                    <v-text-field
                            dense
                            outlined
                            :disabled="configurationsData"
                            persistent-hint
                            label="Pass Key *"
                            class="rounded-lg"
                            v-model="configurationObj.passKey"
                            :hint="errors.get('passKey')"
                            :error="errors.has('passKey')"
                            @input="errors.clear('passKey')"
                        ></v-text-field>
                    </v-col>
                
                    <v-col cols="6">
                        <v-text-field
                            dense
                            outlined
                            persistent-hint
                            label="Confirmation URL *"
                            class="rounded-lg"
                            v-model="configurationObj.confirmationUrl"
                            :hint="errors.get('confirmationUrl')"
                            :error="errors.has('confirmationUrl')"
                            @input="errors.clear('confirmationUrl')"
                        ></v-text-field>
                    </v-col>  
                
                    <v-col cols="6">
                        <v-text-field
                            dense
                            outlined
                            persistent-hint
                            label="Validation URL"
                            class="rounded-lg"
                            v-model="configurationObj.validationUrl"
                            :hint="errors.get('validationUrl')"
                            :error="errors.has('validationUrl')"
                            @input="errors.clear('validationUrl')"
                        ></v-text-field>
                    </v-col>
                
                    <v-col cols="6">
                        <v-text-field
                            dense
                            outlined
                            persistent-hint
                            label="STK Callback URL *"
                            class="rounded-lg"
                            v-model="configurationObj.stkCallbackUrl"
                            :hint="errors.get('stkCallbackUrl')"
                            :error="errors.has('stkCallbackUrl')"
                            @input="errors.clear('stkCallbackUrl')"
                        ></v-text-field>
                    </v-col>
                
            </v-row>
        

        </template>
    </base-dialog-form>
</template>

  
<script>
  import { mapActions, mapGetters } from 'vuex'
  import PayBill from '@/libs/paybill/PayBill'
  
  export default {
    props: {
      dialog: {
        required: true,
        type: Boolean,
      },
      configurationsData : {
        type: Object
      }
    },
  
    data () {
      return {
        loading: false,
        isEdit: false,
        configurationObj: new PayBill(),
      }
    },

    computed: {
    ...mapGetters({
      configurations: 'getConfigurations',
    }),

    errors () {
        return this.configurationObj.form.errors
      },
  },
  
    methods: {
      ...mapActions([
        'setConfigurations',
      ]),
      submit () {
        
      if (!this.loading) {
        this.loading = true
        
        this.configurationObj.consumerKey = btoa(this.consumerKey)
        this.configurationObj.consumerSecret = btoa(this.consumerSecret)
        this.configurationObj.passKey = btoa(this.passKey)

        !this.configurationsData ? this.store() : this.update()
        .finally(() => {
          this.loading = false
        })
      }
    },

    store(){
      this.configurationObj.store().then(() => {
          this.setConfigurations().then(()=> {
            this.$emit('close')
          })
        })
    },

    update(){
      this.configurationObj.update(this.configurationsData.paybillUid).then(() => {
          this.setConfigurations().then(()=> {
            this.$emit('close')
          })
        })
    },
  },
  
    mounted () {
     if(this.configurationsData){
      this.setConfigurations
      Object.keys(this.configurationsData).forEach((key) => {
        if(this.configurationObj.hasOwnProperty(key)){
          const value = this.configurationsData[key]
          this.$set(this.configurationObj, key, value)
        }
      })
     }
    }
  }
  </script>